/**
 * External Dependencies
 */
import 'jquery';
import * as basicScroll from 'basicscroll';
import Splitting from 'splitting';
import Swiper from 'swiper/bundle';
import 'swiper/css/bundle';

$(document).ready(function() {

  // SCROLLED
  $(window).on('scroll', function() {
    if ($(window).scrollTop() > 0) {
      $('body').addClass('scrolled');
    }
    else {
      $('body').removeClass('scrolled');
    }
  });

  // MENU
  $('.hamburger').click(function() {
    $(this).toggleClass('active');
    $('body').toggleClass('hamburger-active');
    $('html').toggleClass('overflow-hidden');
  });

  $('.close-nav').click(function() {
    $('.hamburger').toggleClass('active');
    $('body').toggleClass('hamburger-active');
    $('html').toggleClass('overflow-hidden');
  });

  // ACCORDEON
  $(function() {
    $('.accordeon > .accordeon-item.is-active').children('.accordeon-panel').slideDown();
    $('.accordeon > .accordeon-item').click(function() {
      $(this).siblings('.accordeon-item').removeClass('is-active').children('.accordeon-panel').slideUp();
      $(this).toggleClass('is-active').children('.accordeon-panel').slideToggle('ease-out');
      var $selectedTab = $(this).attr('data-item');
      console.log($selectedTab);
      $('.accordeon-image').removeClass('active');
      $('.accordeon-images').find(`.accordeon-image[data-item='${$selectedTab}']`).addClass('active');
    });
  });


  $(document).on('click', 'a[href^="#"]', function (event) {
    event.preventDefault();
    $('body,html').animate({
      scrollTop: $($.attr(this, 'href')).offset().top - 50,
    }, 500);
  });

  function equalheight() {
    $('.service').each(function(){
      // Cache the highest
      var min_highestBox = 0;
      // Select and loop the elements you want to equalise
      $(this).each(function(){
        // If this box is higher than the cached highest then store it
        if($(this).height() > min_highestBox) {
          min_highestBox = $(this).height();
        }
      });

      // Set the height of all those children to whichever was highest
      $('.service').height(min_highestBox);
      $('.services').height(min_highestBox * 4 + 256);
    });
  }
  equalheight();

  $(window).bind('reisze', equalheight);

  function anchorPosition() {
    var serviceOne = $('.service').outerHeight();
    var serviceTwo = $('.service').outerHeight() * 2 + 256;

    $('.anchor-01').css({ top: serviceOne });
    $('.anchor-02').css({ top: serviceTwo });
  }

  anchorPosition();

  document.querySelectorAll('.service--1').forEach((elem) => {
    let heightService = - $('.service').outerHeight();
    let servicePadding = 129;
    let calcService = heightService + servicePadding;
    basicScroll.create({
      elem: document.querySelector('.anchor-01'),
      from: 'top-middle',
      to: 'bottom-top',
      direct: elem,
      props: {
        '--translate': {
          from: '0',
          to: calcService +'px',
        },
      },
    }).start()
  })

  document.querySelectorAll('.service--2').forEach((elem) => {
    let heightService = - $('.service').outerHeight();
    let servicePadding = 129;
    let calcService = heightService + servicePadding;
    basicScroll.create({
      elem: document.querySelector('.anchor-02'),
      from: 'top-middle',
      to: 'bottom-top',
      direct: elem,
      props: {
        '--translate': {
          from: '0',
          to: calcService + 'px',
        },
      },
    }).start()
  })

  // let navBar = document.querySelector('nav');
  // let prevScrollPos = window.pageYOffset;

  // window.addEventListener('scroll', () => {
  //   let currentScrollPos = window.pageYOffset;
  //   prevScrollPos > currentScrollPos ? navBar.style.transform = "translate3d(0, 0, 0)" : navBar.style.transform = "translate3d(0, -100%, 0)"
  //   prevScrollPos = currentScrollPos;
  // });


  $.fn.isInViewport = function () {
    var elementTop = $(this).offset().top;
    var elementBottom = elementTop + $(this).outerHeight();
    var viewportTop = $(window).scrollTop();
    var viewportBottom = viewportTop + $(window).height();
    return elementBottom > viewportTop && elementTop < viewportBottom;
  };

  $(window).on('resize scroll', function () {
    anchorPosition();
    $('[data-splitting]').each(function () {
      if ($(this).isInViewport()) {
        $(this).addClass('animate-splitting');
      } else {
        $(this).remove('animate-splitting');
      }
    });
  });

  // Reveal
  $('[data-splitting]').each(function () {
    if ($(this).isInViewport()) {
      $(this).addClass('animate-splitting');
    } else {
      $(this).remove('animate-splitting');
    }
  });

    //SPLITTING
    $('body').addClass('loaded');
    const target = document.querySelectorAll('h1.splitting, h2.splitting, h3.splitting');
    Splitting({ target: target, by: 'chars' });

    // Swiper
    new Swiper('.services-swiper', {
      loop: false,
      slidesPerView: 1,
      spaceBetween: 20,
      grabCursor: true,
      breakpoints: {
        768: {
          slidesPerView: 2,
          spaceBetween: 35,
        },
        1024: {
          slidesPerView: 2.5,
        },
      },
      navigation: {
        prevEl: ".swiper-button-prev",
        nextEl: ".swiper-button-next",
      },
    });
});
